(window as any).getEnv = () => {
	const origin = window.location.origin;
	const local = origin.endsWith('bimser.local') && origin !== 'https://synergy.bimser.local';
	return local ? 'https://dev.bimser.net' : window.location.origin;
};

(window as any).getAppVersion = () => {
	return window.APP_VERSION || 'VariableNotSet';
};

import('./bootstrap');
